import { createGlobalStyle } from "styled-components";
import background from '../assets/images/html/PATTERNS.svg'
export const GlobalStyle = createGlobalStyle`

  *{ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Gill Sans';
  }
  body{
    width: 100%;
    background-image: url(${background});
    overflow-x: hidden; 
    height: 100%;
  }
  .react-datepicker-wrapper{
    display: none;
    background: trasparent;
  }

  .react-datepicker__tab-loop{
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
  }

  .react-datepicker__input-container{
    border-radius: 10px 10px 0 0;
    display: flex;

  }

  .react-datepicker__input-container input {
    text-align: center;
    outline: none;
    display: none;
  }

  .react-datepicker-popper{
    position: static !important;
    transform: none !important;
    border: none;
    background: transparent;
    @media screen and (max-width: 562px){
      width: 85%;
    }
  }
  .react-datepicker{
    border: none;
    font-family: 'Gill Sans';
    @media screen and (max-width: 562px){
      width: 100%;
    }
    .react-datepicker__navigation {
      background: transparent;
      color: black;
      top: 0;
    }
    .react-datepicker__header{
      background: transparent;
      height: 60px;
      padding: 10px;
      border: none;
      .react-datepicker__current-month{
        margin-top: 15px;
        margin-bottom: 10px;
        text-transform: capitalize;
        font-size: 15px;
        text-align: left !important;
        color: #003474; 
      }
      .react-datepicker__day-names{
        margin-top: -10px;
        text-transform: capitalize;
        @media screen and (max-width: 562px){ 
          width: 100%;
          .react-datepicker__day-name{
            width: 12.8%;
          }
        }
      }
    }

    &__month-container{
      @media screen and (max-width: 562px){
        width: 100%;
      }
    }
    .react-datepicker__day{
      margin: 1.2px 0;
      border-radius: 0;
      padding: 4px;
      width: 33px;
      @media screen and (max-width: 562px){
        width: 14%;
      }
      :hover{
        background: white;
        border: 0.5px solid #003474;
        border-radius: 4px;
        color: #003474 !important;
      }
    }
    .react-datepicker__triangle{
      display: none;
    }

    .react-datepicker__day--in-range{
      background-color: #D8E3FF !important;
      color: #003474 !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: #003474 !important;
      color: white !important;
      border-radius: 4px;
      &:hover{
        background-color: #003474 !important;
        color: white !important;
      }
    }

    .react-datepicker__navigation--previous {
      left: 70%;
      @media screen and (max-width: 562px){
        left: 80%;
      }
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      border: none !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
      background-color: white;
      color: black;
    } 
    .react-datepicker__day--outside-month {
      color: transparent !important;
      background-color: transparent !important;
      pointer-events: none;
    }

  }

  .btn_nav_color{
    background-color:#003474;
    color:#fff;
    transition: all 0.3s;
  }

  .btn_nav_color:hover{
    background-color:#fff;
    border: 1px solid #003474;
    color:#003474;
  }

  .bg_form{
    background-color:#F1A132;
  }

  .btn_form_mostarda{
    color:#F1A132;
    background-color: white;
  }

  .azul_marinho{
    background-color: #003474;
  }

  .azul_marinho_hover{
    background-color: #083672;
  }

  .border_deixei_sua_menssagen input[type=text], 
  .border_deixei_sua_menssagen input[type=email],
  .border_deixei_sua_menssagen textarea
  {
    border: 1px solid #B4BEC8 !important;
  }

  .form_master_fale_conosco label,
  .form_master_fale_conosco .azul-text{
    color: #003474;
  }

  .politica_privacidade p{
    margin-bottom:15px;
    font-size: 1em;
  }

  .politica_privacidade h2{
    margin-bottom:15px;
    margin-top:35px;
    font-size: 2em;
  }

  .politica_privacidade a{
    color:blue;
  }

  .politica_privacidade ul{
    padding-left:25px;
  }

  .politica_privacidade ul li{
    list-style: disc;
    margin-bottom:15px;
  }

  .politica_privacidade ul{
    padding-left:25px;
  }

  div[aria-roledescription=carousel],
  .react-slideshow-container,
  .react-slideshow-wrapper,
  .each-slide,
  .each-slide div  {
    width: 100%;
    height: 100%;
  }
  .images-wrap{
    height: 100%;
  }

 

 
 
 
  .uk-radio,
  .vai-checkbox {
    border-radius:4px;
    /* 1 */
    display: inline-block;
    height: 16px;
    width: 16px;
    /* 2 */
    overflow: hidden;
    /* 3 */
    margin-top: -4px;
    vertical-align: middle;
    /* 4 */
    -webkit-appearance: none;
    -moz-appearance: none;
    /* 5 */
    background-color: transparent;
    /* 6 */
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #fff;
    transition: 0.2s ease-in-out;
    transition-property: background-color, border;
  }
  .uk-radio {
    border-radius: 50%;
  }
  /* Focus */
  .uk-radio:focus,
  .vai-checkbox:focus {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border-color: #fff;
  }
  /*
   * Checked
   */
  .uk-radio:checked,
  .vai-checkbox:checked,
  .vai-checkbox:indeterminate {
    background-color: #003474;
    border-color: transparent;
  }
  /* Focus */
  .uk-radio:checked:focus,
  .vai-checkbox:checked:focus,
  .vai-checkbox:indeterminate:focus {
    background-color: #003474;
  }
  /*
   * Icons
   */
  .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
  }
  .vai-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  }
  .vai-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
  }

`