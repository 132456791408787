import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './index.css';
import { GlobalStyle } from "./styles/global";

function App() {
  const HomePage = lazy(() => import('./pages/Home'))
  const QuemSomosPage = lazy(() => import('./pages/QuemSomos'))
  const FaleConoscoPage = lazy(() => import('./pages/FaleConosco'))
  const TrabalheConoscoPage = lazy(() => import('./pages/TrabalheConosco'))
  const FaqPage = lazy(() => import('./pages/Faq'))
  const PoliticaPrivacidadePage = lazy(() => import('./pages/PoliticaPrivacidade'))

  return (
    <>
      <GlobalStyle />
      <Router>
        <Suspense fallback={<h1>...</h1>}>
          <Routes>
            <Route element={<HomePage />} path='/' />
            <Route element={<QuemSomosPage />} path='/quem-somos' />
            <Route element={<FaleConoscoPage />} path='/fale-conosco' /> 
            <Route element={<TrabalheConoscoPage />} path='/trabalhe-conosco' /> 
            <Route element={<PoliticaPrivacidadePage />} path='/politica-privacidade' /> 
            <Route element={<FaqPage />} path='/faq' />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
